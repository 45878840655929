import Vue from 'vue'
import Router from 'vue-router'
import layout from './layouts/layout'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: 'partners',
  routes: [
    { path: '/', component: () => import('./views/login') },
    { path: '/', component: layout, children: [
      { path: 'account/profile', component: () => import('./views/account/profile.vue') },
      { path: 'account/setting', component: () => import('./views/account/setting.vue') },
      { path: 'account/password', component: () => import('./views/account/password.vue') },
      { path: 'account/shops', component: () => import('./views/account/shops.vue') },
      { path: 'dashboard', component: () => import('./views/dashboard.vue') },
      { path: 'statistics', component: () => import('./views/statistics.vue') },
      { path: 'statistic/client', component: () => import('./views/statistic/client.vue') },
      { path: 'statistic/customerlogs', component: () => import('./views/statistic/customerlogs.vue') },
      { path: 'statistic/withdrawals', component: () => import('./views/statistic/withdrawals.vue') },
      { path: 'statistic/customer', component: () => import('./views/statistic/customer.vue') },
      { path: 'customers', component: () => import('./views/customers/index.vue'), meta: { alive: true } },
      { path: 'customers/activities', component: () => import('./views/customers/activities.vue'), meta: { alive: true } },
      { path: 'customers/callStatus/:status', component: () => import('./views/customers/callStatus.vue')},
      { path: 'customers/create', component: () => import('./views/customers/create.vue'), meta: { alive: true } },
      { path: 'customers/statistic', component: () => import('./views/customers/statistic.vue'), meta: { alive: true } },
      { path: 'customers/reserve', component: () => import('./views/customers/reserve.vue'), meta: { alive: true } },
      { path: 'customers/:id', component: () => import('./views/customers/view.vue'), meta: { alive: true } },
      { path: 'refers/services', component: () => import('./views/refers/services.vue') },
      { path: 'orders/car', component: () => import('./views/orders/car/index.vue') },
      { path: 'orders/other', component: () => import('./views/orders/other/index.vue') },
      { path: 'orders/car/:id', component: () => import('./views/orders/car/view.vue') },
      { path: 'orders/other/:id', component: () => import('./views/orders/other/view.vue') },
      { path: 'orders/goods', component:() => import('./views/orders/goods.vue') },
      { path: 'orders/services', component:() => import('./views/orders/services.vue') },
      // { path: 'orders/coupons', component:() => import('./views/orders/coupons.vue') },
      // { path: 'orders/userCoupons', component:() => import('./views/orders/userCoupons.vue') },
      { path: 'orders/fuelcards', component:() => import('./views/orders/fuelcards.vue') },
      // { path: 'orders/ETCCards', component:() => import('./views/orders/ETCCards.vue') },
      // { path: 'orders/deposits', component:() => import('./views/orders/deposits.vue') },
      { path: 'orders/topup', component:() => import('./views/orders/topup.vue') },
      { path: 'orders/fuelRecharge', component:() => import('./views/orders/fuelRecharge.vue') },
      // { path: 'orders/violation', component:() => import('./views/orders/violation.vue') },
      // { path: 'orders/mots', component:() => import('./views/orders/mots.vue') },
      // { path: 'orders/insurance', component:() => import('./views/orders/insurance.vue') },
      { path: 'orders/onLineVips', component:() => import('./views/orders/onLineVips.vue') },
      // { path: 'orders/member', component:() => import('./views/orders/member.vue') },
      { path: 'orders/cnpcCards', component:() => import('./views/orders/cnpcCards.vue') },
      // { path: 'orders/designatedCards', component:() => import('./views/orders/designatedCards.vue') },
      { path: 'coupon', component: () => import('./views/coupons/coupons.vue') },
      { path: 'card', component: () => import('./views/cards/cards.vue') },
      { path: 'card/create', component: () => import('./views/cards/create.vue') },
      { path: 'coupon/create', component: () => import('./views/coupons/create.vue') },
      { path: 'transitions', component: () => import('./views/transitions/index.vue') },
      { path: 'transitionsInfo', component: () => import('./views/transitions/transitionsInfo.vue') },
      { path: 'withdrawals', component: () => import('./views/transitions/withdrawals.vue') },
      { path: 'export', component: () => import('./views/coupons/export.vue') },
      { path: 'transitions/:id', component: () => import('./views/transitions/view.vue') },
      { path: 'coupon/transitions/:id', component: () => import('./views/coupons/view.vue') },
      { path: 'profile', component: () => import('./views/profile.vue') },
      { path: 'settings', component: () => import('./views/settings.vue') },
      { path: 'staffs', component: () => import('./views/staffs/staffs.vue') },
      { path: 'staffs/create', component: () => import('./views/staffs/create.vue') },
      { path: 'smsTemplates', component: () => import('./views/sms/sms.vue') },
      { path: 'smsWarnings', component: () => import('./views/sms/warnings.vue') },
      { path: 'smsWarnings/:id', component: () => import('./views/sms/view.vue') },
    ], meta: { auth: true }},
    { path: '*', component: import('./views/error') }
  ]
})

export default router
