import Vue from 'vue'
import { $http } from 'element-go'

Vue.prototype.$app = {
  name: process.env.VUE_APP_NAME,
  siteURL: process.env.VUE_APP_SITEURL,
  baseURL: process.env.VUE_APP_BASEURL,
  wxopenAppId:  process.env.VUE_APP_WXOPEN_APPID
}

/**
 * 登陆逻辑
 */
Vue.prototype.$goto = function(url) {
  this.$router.push(url)
}

/**
 * 前往登陆
 */
Vue.prototype.$login = function() {
  let refer = this.$route.path
  this.$router.replace('/?refer=' + refer)
}

/**
 * 完成登陆
 */
Vue.prototype.$logged = function(user) {
  $http.setToken(user.access_token)
  this.$store.commit('user', user)
  this.$router.push( this.$route.query.refer ? this.$route.query.refer : '/dashboard' )
}

/**
 * 退出逻辑
 */
Vue.prototype.$logout = function() {
  $http.delToken()
  this.$store.commit('_user')
  this.$router.replace('/')
}

/**
 * 日期格式
 * @param symbol
 * @returns {string}
 */
String.prototype.dateString = function() {
  if (this.substr(0, 10) == '1970-01-01') return '-' // 临时处理
  return this.substr(0, 10)
}

/**
 * 电话格式
 */
String.prototype.phoneNumber = function(nake = false) {
  let ret = this.replace('+86', '')
  if (nake) return ret
  let len = ret.length
  if (!len) return '-'
  return ret.substr(0, len - 8) + '****' + ret.substr(len - 4)
}

Number.prototype.phoneNumber = function() {
  return this.toString().phoneNumber()
}


/**
 * 货币格式
 */

 String.prototype.price = function() {
  return '￥' + this.points()
}

Number.prototype.price = function() {
  return this.toString().price()
}

/**
 * 千分位格式（字符）
 * @param symbol
 * @returns {string}
 */
String.prototype.points= function() {
  return parseFloat(this).toFixed(2).toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
}

Number.prototype.points = function() {
  return this.toString().points()
}


/**
 * 千分位格式（字符）
 * @param symbol
 * @returns {string}
 */
String.prototype.thousands = function() {
  return parseInt(this).toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
}

Number.prototype.thousands = function() {
  return this.toString().thousands()
}
