import { $http } from 'element-go'
import NProgress from 'nprogress'
import router from './router'
import store from './store'
import $api from "./api"
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

router.beforeEach(async (to, from, next) => {

  // 加载进度
  NProgress.start()

  // 页面标题
  document.title = (to.meta.title ? to.meta.title + ' - ' : '') + process.env.VUE_APP_NAME

  // 登陆验证
  if (to.matched.some(r => r.meta.auth)) {
    let path = '/?refer=' + to.path // 登陆地址
    let user = store.state.user
    if (!user.id) {
      let token = $http.getToken()
      if (token) {
        let { data } = await $api.profile({ with: 'shop' }).catch(() => {
          $http.delToken()
          return next({ path })
        })
        store.commit('user', user = data)
      }
    }
    if (!user.shop || !user.shop.id) return next({ path }) // 没有店铺

    // 店铺
    store.commit('shop', user.shop)

    // 权限 - 临时方案
    const pivot = user.shop.pivot
    let premissions = { role: {} }
      premissions.coupon_id = pivot.coupon_id
    if (user.shop.statistic_withdrawals == 1) {
      premissions.statisticWithdrawals  = true // 提现报表
      premissions.serviceOrders  = true
    }
    if (user.shop.statistic_withdrawals == 2) {
      premissions.serviceOrders  = true // 提现报表
    }
    
    if (user.shop.statistic == 1) {
      premissions.statisticData  = true // 业务报表
    }
    
    if (pivot.role_id == 1 || pivot.is_owner) {
      premissions.role.supervisor = true // 经理角色
      premissions.customer = true // 客户
      premissions.customerActivities = true // 活动客户
      premissions.finance = true // 财务
      premissions.statistic = true // 报表
      premissions.setting = pivot.self_scope ? false :true // 设置
      premissions.car_service = user.shop.car_service > 0 // 车务提醒
      premissions.sms = user.shop.car_service > 0 // 短信模板
    }
    if (pivot.role_id == 2) {
      premissions.role.caller = true // 坐席角色
      premissions.customer = true // 客户
      premissions.car_service = user.shop.car_service == 2 // 车务提醒
    }

    premissions.statistic = premissions.serviceOrders? false :premissions.statistic
    premissions.customer = premissions.serviceOrders? false :premissions.customer

    store.commit('premissions', premissions)
  }

  // 页面参数
  store.commit('meta', to.meta)

  return next()
})

router.afterEach(() => { NProgress.done() })
