import Vue from "vue"
import { $http } from 'element-go'
import { Message } from 'element-ui'
import axios from 'axios';

$http.configure({
  baseURL: process.env.VUE_APP_BASEURL,
  timeout: 30000,
  resolve(ret) {
    if (ret.data.code != 200 ) return Promise.reject(ret.data)
    return ret.data
  },
  reject(err) {
    if (err.response && err.response.status === 401) location.reload() // 无权限或登陆超时
    else Message({ type: 'error', message: err.message })
    return Promise.reject(err)
  }
})

$http.download = function (url, params, filename){
  const baseURL = this.baseURL
  const responseType = 'blob'
  const headers = {}
  if (this.token) headers.Authorization = 'Bearer ' + this.getToken()
  return axios.get(url, { headers: { ...headers, ...this.headers }, params, responseType, baseURL }).then(ret => {
    if (window.navigator.msSaveOrOpenBlob) return navigator.msSaveBlob(ret.data, filename)
    var link = document.createElement('a')
    link.href = window.URL.createObjectURL(ret.data)
    link.download = filename
    link.click()
    window.URL.revokeObjectURL(link) // 释放内存
  })
}

export default Vue.prototype.$api = {
  go(para) { return $http.get('/' + para.go, para) }, 
  coupons(para) { return $http.get('/v1/coupons', para) },                                            // 卡券列表
  scan(code, para) { return $http.post('v1/wechat/scan/' + code, para) },                             // 扫码登陆
  login(para) { return $http.post('v1/user/login', para) },                                           // 用户登陆
  profile(para) { return $http.get('v1/user/profile', para) },                                        // 用户资料
  invite(para) { return $http.get('v1/shop/invite', para) },                                          // 加店二维码
  setting(para) { return $http.post('v1/user/setting', para) },                                       // 更新资料
  password(para) { return $http.post('v1/user/password', para) },                                     // 更新密码
  toggle(para) { return $http.post('/v1/user/toggle', para) },                                        // 切换商户
  callClient(para) { return $http.get('/v1/shop/customers/call_client', para) },                      // 商户成员
  customersStatistics(para) { return $http.get('/v1/shop/customers/statistics', para) },              // 未分配客户数

  captcha (para) { return $http.post('v1/user/captcha', para) },                                       // 发送验证
  account(para) { return $http.get('/v1/shop/account', para) },                                       // 商户账户
  joinQuit(para) { $http.post('/v1/shop/quit', para) },                                               // 退出商户
  joinCode(para) { return $http.get('/v1/shop/code', para) },                                         // 邀请加入
  roles(para) { return $http.get('v1/shop/roles', para) },
  staffSelect(para) { return $http.get('/v1/shop/users', para) },                                     // 商户成员
  staffCreate(para) { return $http.put('/v1/shop/users/add', para) },                               // 创建成员
  staffUpdate(id, para) { return $http.patch('/v1/shop/users/' + id, para) },                         // 修改成员
  staffCaller(id, para) { return $http.patch('/v1/shop/users/' + id + '/caller', para) },             // 绑定座席
  staffRemove(id, para) { return $http.delete('/v1/shop/users/' + id, para) },                        // 删除成员
  photoRemove(para) { $http.post('/v1/shop/photos/remove', para) },                                   // 删除照片
  orderCouponSelect(para) { return $http.get('/v1/shop/orders/coupons', para) },                      // 卡券订单
  userCoupons(para) { return $http.get('/v1/shop/userscoupons', para) },                            // 卡券订单
  userCouponsExport(para, filename) { return $http.get('v1/shop/userscoupons', para, filename) },
  shopExport(para) { return $http.get('v1/shop/export', para) },
  orderCouponCreate(para) { return $http.post('/v1/shop/coupon/sending', para) },                     // 发送卡券
  cardBatchCreate(para) { return $http.post('/v1/shop/card/batch', para) },                           // 批量生成卡密
  cardsSelect(para) { return $http.get('/v1/shop/cards', para) }, 
  cardsCancel(id, para) { return $http.post('v1/shop/cards/' + id + '/cancel', para) },
  cardsExport(para, filename) { return $http.download('v1/shop/cards', para, filename) },
  customersDispatch(para) { return $http.post('/v1/shop/customers/dispatch', para) },                 // 发送卡券
  transitionSelect(para) { return $http.get('/v1/shop/capital/transitions', para) },                  // 转账记录
  transitionExport(para, filename) { return $http.download('v1/shop/capital/transitions', para, filename) },
  transitionDetail(id, para) { return $http.get('/v1/shop/capital/transitions/' + id, para) },        // 转账详情
  transitionDelete(id, para) { return $http.delete('/v1/shop/capital/transitions/' + id, para) },     // 删除转账
  transitionModify(id, para) { return $http.patch('/v1/shop/capital/transitions/' + id, para) },      // 修改转账
  transitionCreate(para) { return $http.post('/v1/shop/capital/transitions', para) },                 // 创建转账
  capitalTransitionItems(para) { return $http.get('v1/shop/capital/items', para) },
  capitalTransitionItemsExport(para, filename) { return $http.download('v1/shop/capital/items', para, filename) },
  capitalTransitionsCancel(id, para) { return $http.post('v1/shop/capital/transitions/' + id + '/cancel', para) },
  capitalTransitionsDelete(id, para) { return $http.post('v1/shop/capital/transitions/' + id + '/delete', para) },
  couponTransitionSelect(para) { return $http.get('/v1/shop/coupon/transitions', para) },            // 卡券转账记录
  couponTransitionExport(para, filename) { return $http.download('v1/shop/coupon/transitions', para, filename) },
  couponTransitionDetail(id, para) { return $http.get('/v1/shop/coupon/transitions/' + id, para) },        // 卡券转账详情
  couponTransitionDelete(id, para) { return $http.delete('/v1/shop/coupon/transitions/' + id, para) },     // 删除卡券转账
  couponTransitionModify(id, para) { return $http.patch('/v1/shop/coupon/transitions/' + id, para) },      // 修改卡券转账
  couponTransitionCreate(para) { return $http.post('/v1/shop/coupon/transitions', para) },  
  customerSelect(para) { return $http.get('/v1/shop/customers', para) },                            // 客户查询
  customerActivities(para) { return $http.get('/v1/shop/customer/activities', para) },                          // 活动客户查询
  customerActivitiesExport(para, filename) { return $http.download('v1/shop/customer/activities', para, filename) },
  customerReserve(para) { return $http.get('/v1/shop/customers/reserve_call', para) },                // 客户预约
  customerReserveRemove(id, para) { return $http.delete('/v1/shop/customers/reserve_call/' + id, para) },          // 删除客户预约
  shopsUserLog(para) { return $http.get('/v1/shop/shopuserlogs', para) },                             // 操作记录
  customerLog(para) { return $http.get('/v1/shop/customers/call_status', para) },    
  customerExport(para, filename) { return $http.download('v1/shop/customers', para, filename) },
  statisticClient(para) { return $http.get('v1/shop/call/client/statistic', para) },
  statisticCustomers(para) { return $http.get('v1/shop/customers/user/statistic', para) },
  statisticWithdrawals(para) { return $http.get('v1/shop/capital/transitions/statistic', para) },
  statisticWithdrawalsExport(para, filename) { return $http.download('v1/shop/capital/transitions/statistic', para, filename) },
  customerDetail(id, para) { return $http.get('/v1/shop/customers/' + id, para) },                    // 客户详情
  customerStatistic(para) { return $http.get('/v1/shop/call/client/status/statistic', para) }, 
  customernextPrev(para) { return $http.post('/v1/shop/customers/next_prev', para) },     
  customerRemove(id, para) { return $http.delete('/v1/shop/customers/' + id, para) },                 // 删除客户
  customerModify(id, para) { return $http.patch('/v1/shop/customers/' + id, para) },                  // 修改客户
  customerCreate(para) { return $http.post('/v1/shop/customers', para) },                             // 创建客户
  clientOnline(para) { return $http.post('/v1/shop/call/client/online', para) },                      // 坐席上线
  clientCallout(para) { return $http.post('/v1/shop/call/callout', para) },                           // 坐席外呼
  clientInfo(para) { return $http.get('/v1/shop/call/client/info', para) },                           // 坐席详情
  customerInsuranceQuery(para)  { return $http.get('/v1/shop/customers/insurance_query', para) },     // 续保查询
  customerPhoneCall(id, para)  { return $http.post('/v1/shop/customers/' + id + '/phone_call', para) },   // 电话呼叫（弃用）
  customerStatusUpdate(id, para) { return $http.put('/v1/shop/customers/call_status/' + id, para) },      // 更新呼叫状态
  customerMakeAppointment(id, para) { return $http.post('/v1/shop/customers/reserve_call/' + id, para) }, // 创建预约
  insuranceCreate(id, para) { return $http.post('/v1/shop/customers/insurance/' + id, para) },            // 新增保单信息
  insuranceUpdate(id, para) { return $http.put('/v1/shop/customers/insurance/' + id, para) },             // 更新保单信息
  customershowPhone(id) {return $http.get('/v1/shop/customers/show_phone/' + id) },                       // 显示电话
  referServices(para) { return $http.get('/v1/shop/refers/services', para) },                             // 分销订单
  referServicesExport(para, filename) { return $http.download('v1/shop/refers/services', para, filename) },
  capitalWithdrawals(para) { return $http.get('v1/shop/capital/withdrawals', para) },
  capitalWithdrawalsExport(para, filename) { return $http.download('v1/shop/capital/withdrawals', para, filename) },
  // 短信模板
  smsTemplates(para) { return $http.get('v1/shop/car/warning/sms/templates', para) },
  smsTemplatesStore(para) { return $http.post('v1/shop/car/warning/sms/templates', para) },
  smsTemplatesUpdate(id, para) { return $http.patch('v1/shop/car/warning/sms/templates/' + id, para) },
  smsTemplatesDestroy(id) { return $http.delete('v1/shop/car/warning/sms/templates/' + id) },
  warnings(para) { return $http.get('v1/shop/car/warnings', para) },
  warningsDetail(id, para) { return $http.get('/v1/shop/car/warnings/' + id, para) },
  warningsStore(para) { return $http.post('v1/shop/car/warnings/upload', para) },
  warningsUpdate(id, para) { return $http.patch('v1/shop/car/warnings/' + id, para) },
  warningsDestroy(id, para) { return $http.delete('v1/shop/car/warnings/' + id, para) },
  carOrder( para) { return $http.get('v1/shop/car/order', para) },
  carOrderDate(date, para) { return $http.get('v1/shop/car/order/' + date, para) },
  carOrderExport(date, para, filename) { return $http.download('v1/shop/car/order/' + date, para, filename) },
  token() { return $http.getToken() },
  orderGoods(para) { return $http.get('v1/shop/orders/goods', para) },
  orderGoodsExport(para, filename) { return $http.download('v1/shop/orders/goods', para, filename) },
  orderServices(para) { return $http.get('v1/shop/orders/shopservices', para) },
  orderServicesExport(para, filename) { return $http.download('v1/shop/orders/shopservices', para, filename) },
  orderFuelcards(para) { return $http.get('v1/shop/orders/fuelcards', para) },
  orderFuelcardsExport(para, filename) { return $http.download('v1/shop/orders/fuelcards', para, filename) },
  orderFuelRecharge(para) { return $http.get('v1/shop/orders/fuelRecharge', para) },
  orderFuelRechargeExport(para, filename) { return $http.download('v1/shop/orders/fuelRecharge', para, filename) },
  orderCnpcCards(para) { return $http.get('/v1/shop/orders/cnpccards', para) },
  orderCnpcCardsExport(para, filename) { return $http.download('v1/shop/orders/cnpccards', para, filename) },
  orderOnline(para) { return $http.get('/v1/shop/orders/onlinevips', para) },
  orderOnlineExport(para, filename) { return $http.download('v1/shop/orders/onlinevips', para, filename) },
  orderTopup(para) { return $http.get('v1/shop/orders/topup', para) },
  orderTopupExport(para, filename) { return $http.download('v1/shop/orders/topup', para, filename) },
  userCouponDelete(para) { return $http.delete('v1/shop/userscoupons/' + para.id + '/delete', para) },
}
