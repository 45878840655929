import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    meta: {},         // 路由（属性）
    user: {},         // 用户
    shop: {},         // 店铺
    premissions: {},  // 权限
    customerIndex: {},
  },
  getters: {
    meta: (state) => { return state.meta },
    user: (state) => { return state.user },
    shop: (state) => { return state.shop },
    premissions: (state) => { return state.premissions },
    customerIndex: (state) => { return state.customerIndex },
  },
  mutations: {
    meta (state, data) { state.meta = data },
    user (state, data) { state.user = data },
    shop (state, data) { state.shop = data },
    premissions (state, data) { state.premissions = data },
    customerIndex (state, data) { state.customerIndex = data },
    _meta (state) { state.meta = {} },
    _user (state) { state.user = {} },
    _shop (state) { state.shop = {} },
    _premissions (state) { state.premissions = {} },
    _customerIndex (state) { state.shop = {} },
  }
})
