import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocaleUI from 'element-ui/lib/locale/lang/en'
import zhLocaleUI from 'element-ui/lib/locale/lang/zh-CN'
import enLocaleGo from 'element-go/lib/locale/lang/en'
import zhLocaleGo from 'element-go/lib/locale/lang/zh-CN'
import enLocale from '../locales/en'
import zhLocale from '../locales/zh-CN'

Vue.use(VueI18n)

export default new VueI18n({
    locale: process.env.VUE_APP_LANG,
    fallbackLocale: process.env.VUE_APP_LANG,
    messages: {
        en: {
            ...enLocaleUI,
            ...enLocaleGo,
            ...enLocale
        },
        zh: {
            ...zhLocaleUI,
            ...zhLocaleGo,
            ...zhLocale
        }
    }
})