<template>
  <ex-layout
    :logo="logo" :menu="menu" :alive="meta.alive"
    :user="shop.title + '-' + user.nickname" :user-menu="userMenu" @logout="logout" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      logo: require('../assets/logo.png'),
    }
  },
  computed: {
    ...mapGetters(['meta', 'user', 'shop', 'premissions']),
    menu() {
      return [
        { title: '首页', path: '/dashboard', icon: 'el-icon-data-board' },
        // { title: '数据', path: '/statistics', icon: 'el-icon-s-data' },
        { title: '客户', path: '/customers', icon: 'el-icon-user', hide: !this.premissions.customer, children: [
          { title: '所有客户', path: '/customers', children: [
            { title: '所有客户', path: '/customers' },
            { title: '预约客户', path: '/customers/reserve' },
            { title: '已拒绝', path: '/customers/callStatus/2' },
            { title: '空号', path: '/customers/callStatus/6' },
            { title: '其它', path: '/customers/callStatus/7' },
            
          ]},
          { title: '活动客户', path: '/customers/activities', hide: !this.premissions.customerActivities },
          { title: '通时通次', path: '/customers/statistic' },
        ]},
        { title: '订单', path: '/orders', icon: 'el-icon-shopping-cart-2', hide: !this.premissions.order , children: [
          { title: '分销订单', path: '/refers/services' },
        ]}, 
        { title: '订单', path: '/orders/goods', icon: 'el-icon-shopping-cart-2', hide: !this.premissions.serviceOrders , children: [
          
          { title: '商品订单', path: '/orders/goods' },
          { title: '服务订单', path: '/orders/services' },
          { title: '油卡订单', path: '/orders/fuelcards', children: [
              {title: '油卡卡券', path: '/orders/fuelcards'},
              {title: '油卡充值', path: '/orders/fuelRecharge'},
              {title: '中石油卡券', path: '/orders/cnpcCards'},
          ]},
          // { title: '代驾卡券', path: '/orders/designatedCards' },
          // { title: '余额充值', path: '/orders/deposits' },
          { title: '话费充值', path: '/orders/topup' },
          // { title: '违章订单', path: '/orders/violation' },
          // { title: '年检订单', path: '/orders/mots' },
          // { title: '路费卡券', path: '/orders/etccards' },
          { title: '会员订单', path: '/orders/onLineVips' },
          // { title: '付费会员', path: '/orders/member' },
          { title: '保养订单', path: '/orders/car' }
        ]}, 
        { title: '财务', path: '/coupon', icon: 'el-icon-coin', hide: !this.premissions.finance , children: [
          { title: '赠送卡券', path: '/coupon' },
          { title: '余额转账', path: '/transitions' },
          { title: '转账详情', path: '/transitionsInfo', hide: !this.premissions.setting  },
          { title: '提现记录', path: '/withdrawals' , hide: !this.premissions.statisticWithdrawals},
          { title: '导出列表', path: '/export' },
          { title: '制作卡密', path: '/card' , hide: this.premissions.serviceOrders},
        ]},
        { title: '报表', path: '/statistic/client', icon: 'el-icon-s-data', hide: !this.premissions.statistic , children: [
          { title: '业务统计', path: '/statistic/client' },
          { title: '业务流水', path: '/statistic/customerlogs' },
          { title: '提现报表', path: '/statistic/withdrawals' , hide: !this.premissions.statisticWithdrawals},
          { title: '业务报表', path: '/statistic/customer' , hide: !this.premissions.statisticData}
        ]},
        { title: '提醒', path: '/smsWarnings', icon: 'el-icon-bell', hide: !this.premissions.car_service , children: [
          { title: '车务提醒', path: '/smsWarnings',hide: !this.premissions.car_service },
        ]},

        { title: '设置', path: '/settings', icon: 'el-icon-setting', children: [
          { title: '商户资料', path: '/profile' },
          { title: '员工权限', path: '/staffs', hide: !this.premissions.setting },
          { title: '短信模板', path: '/smsTemplates', hide: !this.premissions.sms },

        ]},
      ]
    },
    userMenu() {
      return [
        { title: '资料', path: '/account/profile' },
        { title: '设置', path: '/account/setting' },
        { title: '密码', path: '/account/password' },
        { title: '店铺', path: '/account/shops', hide: this.user.shops.length <= 1 },
      ]
    }
  },
  methods: {
    logout() {
      this.$logout()
    }
  }
}
</script>
